import { Config } from "@/types";
import { useQuery } from "@tanstack/react-query";

export function useFetchConfig() {
  return useQuery({
    queryKey: ['config'],
    queryFn: async (): Promise<Config> => {
      return fetch('/config', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then((res) => res.json());
    }
  })
}