import { Config } from '@/types';
import React from 'react';
import { createUseContext } from '../core/utils/create-use-context';
import { useFetchConfig } from "./useFetchConfig";
import { createServices } from "../core/init";
import { ServicesContextProvider } from "./ServicesContext";

const ConfigContext = React.createContext<Config | null>(null);
ConfigContext.displayName = 'ConfigContext';

export const useConfigContext = createUseContext(ConfigContext);

export const ConfigContextProvider = ConfigContext.Provider;

type ConfigProviderProps = React.PropsWithChildren;

export function ConfigLoader(props: ConfigProviderProps) {
  const { children } = props;
  const { data, status } = useFetchConfig();

  if (status === "pending") {
    return null;
  }

  if (status === "error") {
    // TODO: No config, what should we show
    return null;
  }

  const services = createServices({ backendUrl: '' } , data);

  return (
    <ConfigContextProvider value={data}>
      <ServicesContextProvider value={services}>
        {children}
      </ServicesContextProvider>
    </ConfigContextProvider>
  );
}