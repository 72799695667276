import { PropsWithChildren } from 'react';

import '../../../index.css';

export function AppLayout(props: PropsWithChildren) {
  const { children } = props;
  return (
    <>
      { children }
    </>
  )
}
