import { PurchaseApi } from "../purchase/purchase-api";
import { SentryService } from "../core/sentry/context";
import { createContext } from "react";
import { createUseContext } from "../core/utils/create-use-context";

type ServicesContextValue = {
  purchaseApi: PurchaseApi;
  sentryService: SentryService;
}

const ServicesContext = createContext<ServicesContextValue | null>(null);

ServicesContext.displayName = 'ServicesContext';

export const useServicesContext = createUseContext(ServicesContext);

export const ServicesContextProvider = ServicesContext.Provider;