import { ErrorBoundary } from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';

import { AppLayout } from './modules/core/app/app-layout';
import { AppProvider } from './modules/core/app/provider';
import { SEO } from './modules/core/app/seo';
import { ErrorPage } from './modules/core/errors/error-page';
import { createRouter } from './routes';
import { ConfigLoader } from "./modules/config/ConfigContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'always',
      refetchOnWindowFocus: false
    }
  }
});

function renderApplication() {
  const router = createRouter();
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
      <HelmetProvider>
        <ErrorBoundary
          fallback={
            <ErrorPage
              title="Something went wrong."
              description="Please try again later. If the problem persists, please contact support."
            />}>
          <SEO />
          <QueryClientProvider client={queryClient}>
            <ConfigLoader>
              <AppProvider>
                <AppLayout>
                  <RouterProvider router={router} />
                </AppLayout>
              </AppProvider>
            </ConfigLoader>
          </QueryClientProvider>
        </ErrorBoundary>
      </HelmetProvider>
    </React.StrictMode>
  );
}

function startApp() {
  return renderApplication();
}

void startApp();