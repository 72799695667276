import { stringify } from 'qs';

export const AUTH_HEADERS = {
  'X-Ask-Blue-J-Request': 'true'
};

export type Methods = 'POST' | 'GET' | 'DELETE' | 'PUT';

type RouteGeneratorFn<TParams = never> = (params: TParams) => string;

export class ResponseError extends Error {
  public status?: number;

  constructor(message: string, status?: number) {
    super(message);
    this.status = status;
  }
}

function createUrl<RouteParams>(apiSubRoute: string | (RouteGeneratorFn<RouteParams>), payload?: RouteParams) {
  let url;

  if (typeof apiSubRoute === 'string') {
    url = apiSubRoute
  } else if (typeof apiSubRoute === 'function' && payload) {
    url = apiSubRoute(payload);
  }

  if (!url) {
    throw new Error('Unable to make request, URL not provided or unable to generate');
  }

  return url;
}

export abstract class Api {
  constructor(protected readonly backendUrl = '') {}

  protected baseHeaders(): Record<string, string> {
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };
  }

  protected apiFetch(url: string, requestParams: RequestInit, signal?: AbortSignal): Promise<Response> {
    return fetch(`${this.backendUrl}${url}`, {
      ...requestParams,
      credentials: 'include',
      headers: {
        ...this.baseHeaders(),
        ...AUTH_HEADERS,
        ...(requestParams.headers || {})
      },
      signal
    });
  }

  protected createFetch<TPayload, TResponse, TParams>(apiSubRoute: string | (RouteGeneratorFn<TParams>), method: Methods = 'POST') {
    return async (payload?: TPayload, params?: TParams): Promise<TResponse> => {
      const requestParams: RequestInit = {
        method
      };

      let url = createUrl(apiSubRoute, params);

      if (payload) {
        if (method === 'POST' || method === 'PUT') {
          requestParams.body = JSON.stringify(payload);
        } else if (method === 'GET') {
          url = `${url}${stringify(payload, { addQueryPrefix: true })}`;
        }
      }

      const response = await this.apiFetch(url, requestParams);

      return await response.json();
    };
  }
}
