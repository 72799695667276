import { useMutation } from '@tanstack/react-query';
import React from 'react';

import { useServicesContext } from '../config/ServicesContext';
import { PurchaseFormData, PurchasePayload, PurchaseResponse, Region } from './purchase-api';
import { useRecaptcha } from './recaptcha';

export function usePurchaseSubmit() {
  const { purchaseApi } = useServicesContext();
  const { getFormSubmitRecaptchaToken } = useRecaptcha();

  const mutation = useMutation<PurchaseResponse, unknown, PurchasePayload>({
    mutationFn: (data) => {
      return purchaseApi.purchase(data)
    }
  });

  return React.useCallback(async (region: Region, data: PurchaseFormData): Promise<PurchaseResponse> => {
    const recaptchaToken = await getFormSubmitRecaptchaToken();
    const submitData = {
      ...data,
      region,
      recaptchaToken
    }
    return mutation.mutateAsync(submitData).then((result) => {
      return result;
    });
  }, [getFormSubmitRecaptchaToken]);
}

