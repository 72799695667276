import * as Sentry from '@sentry/react';
import { Extras } from '@sentry/types';
import React, { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import { createUseContext } from '../utils/create-use-context';

export interface ISentryService {
  reportError: (err: Error, extras?: Extras) => void;
  reportMessage: (message: string, extras?: Extras) => void;
}

export class SentryService implements ISentryService {
  constructor(dsn: string, environment: string) {
    if (!dsn) {
      return;
    }
    Sentry.init({
      dsn,
      environment,
      integrations: [
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        }),
      ]
    });
  }

  public reportMessage = (message: string, extras?: Extras) => {
    Sentry.withScope((scope) => {
      if (extras) {
        scope.setExtras(extras);
      }
      Sentry.captureMessage(message);
    });
  };

  public reportError = (err: Error, extras?: Extras) => {
    Sentry.withScope((scope) => {
      if (extras) {
        scope.setExtras(extras);
      }
      Sentry.captureException(err);
    });
  };
}

const SentryContext = React.createContext<{ sentry: ISentryService } | null>(null);
SentryContext.displayName = 'SentryContext';

export const useSentryContext = createUseContext(SentryContext);

export const SentryContextProvider = SentryContext.Provider;
