import React from 'react';
import { Navigate } from 'react-router-dom';

import { sentryCreateBrowserRouter } from './modules/core/sentry/utils';
import { LandingRoute } from './modules/purchase/LandingRoute';

export function createRouter() {
  return sentryCreateBrowserRouter([
    {
      path: '/',
      element: <LandingRoute region="us" />
    },
    {
      path: '/ca',
      element: <LandingRoute region="ca" />
    },
    {
      path: '*',
      element: <Navigate to="/" replace />
    },
  ]);
}
