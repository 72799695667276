import React from 'react';

import { FeatureFlagProvider } from '../feature-flag/context';
import { DevToolsProvider } from './debug-provider';

type AppProviderProps = React.PropsWithChildren;

export const AppProvider = (props: AppProviderProps) => {
  return (
    <FeatureFlagProvider>
      <DevToolsProvider>
        {props.children}
      </DevToolsProvider>
    </FeatureFlagProvider>
  );
}
