import clsx from 'clsx';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { PurchaseFormData, Region } from './purchase-api';
import { usePurchaseSubmit } from './usePurchaseSubmit';

export function PurchaseForm({ region }: { region: Region }) {
  const [submissionFailed, setSubmissionFailed] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const { register, handleSubmit } = useForm<PurchaseFormData>();

  const submitHandler = usePurchaseSubmit();

  const onSubmit: SubmitHandler<PurchaseFormData> = async (data) => {
    setSubmissionFailed(false);
    setSubmitting(true);
    await submitHandler(region, data).then((result) => {
      if (result && result.url) {
        window.location.href = result.url;
      }
    }).catch((err) => {
      setSubmissionFailed(true);
      // TODO: Can we do anything with a fail?
      console.error('Failed to initiate purchase flow', { err });
    }).finally(() =>  setSubmitting(false));
  }

  const inputClasses = [
    "border",
    "border-neutralGrey",
    "bg-offWhite",
    "placeholder-otherWhite",
    "mb-8",
    "rounded-sm",
    "min-w-full",
    "max-w-full"
  ];

  const labelClasses = [
    'mb-0.5',
  ];

  const baseUrl = `https://www.bluej.com${region === 'ca' ? '/ca' : ''}`;

  return (
    <>
      { submissionFailed && (
        <div className="p-2 mb-4 bg-red-100 border border-red-500">
          Submission Failed
        </div>
      )}
      <div className="">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-6">
            <div className="flex md:gap-6 flex-col md:flex-row">
              <div className="flex-1">
                <div>
                  <label htmlFor="firstName" className={clsx(labelClasses)}>
                    First name
                    <span className="text-red">&nbsp;*</span>
                  </label>
                </div>
                <div>
                  <input
                    id="firstName"
                    className={clsx(inputClasses)}
                    placeholder="First name"
                    type="text"
                    {...register('firstName', { required: true })}
                  />
                </div>
              </div>
              <div className="flex-1">
                <div>
                  <label htmlFor="lastName" className={clsx(labelClasses)}>
                    Last name
                    <span className="text-red">&nbsp;*</span>
                  </label>
                </div>
                <div>
                  <input
                    id="lastName"
                    className={clsx(inputClasses)}
                    placeholder="Last name"
                    type="text"
                    {...register('lastName', { required: true })}
                  />
                </div>
              </div>
            </div>
            <div>
              <div>
                <label htmlFor="email" className={clsx(labelClasses)}>
                  Email
                  <span className="text-red">&nbsp;*</span>
                </label>
              </div>
              <div>
                <input
                  className={clsx(inputClasses)}
                  placeholder="Email"
                  id="email"
                  type="email"
                  {...register('email', { required: true })} />
              </div>
            </div>
            <div>
              <div>
                <label htmlFor="organizationName" className={clsx(labelClasses)}>
                  Organization
                  <span className="text-red">&nbsp;*</span>
                </label>
              </div>
              <div>
                <input
                  className={clsx(inputClasses)}
                  placeholder="Organization"
                  type="text"
                  {...register('organizationName', { required: true })}
                />
              </div>
            </div>
            <div className="mb-4">
              <div>
                <input type="checkbox" className="inline-block mr-2"
                       id="terms"
                       value="agree" {...register('terms', { required: true })} />
                <label htmlFor="terms">
                  I have read and agree to the Blue J <a href={`${baseUrl}/subscriber-agreement`}
                                                         rel="noreferrer noopener" target="_blank">Subscriber
                  Agreement</a>, <a href={`${baseUrl}/terms-of-use`} rel="noreferrer noopener" target="_blank">Terms
                  of Use</a>, and <a href={`${baseUrl}/privacy-notice`} rel="noreferrer noopener"
                                     target="_blank">Privacy Notice</a>.
                  <span className="text-red">&nbsp;*</span>
                </label>
              </div>
            </div>
          </div>
          <div>
            <button type="submit"
                    disabled={submitting}
                    className="font-semibold bg-cabaret px-8 py-3 hover:bg-hoverRed transition-colors rounded-lg text-black disabled:bg-raven">Buy
              Now
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
