import { PageTitle } from '../core/app/page-title';
import { Region } from './purchase-api';
import { PurchaseForm } from './PurchaseForm';


export function LandingRoute({ region }: { region: Region }) {
  return (
    <>
      <PageTitle title={'Purchase'} />
      <div>
        <PurchaseForm region={region} />
      </div>
    </>
  )
}
