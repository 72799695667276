import React from 'react';
import { useFlags, LDProvider } from 'launchdarkly-react-client-sdk';

import { useConfigContext } from '../../config/ConfigContext';

export function FeatureFlagProvider({ children }: React.PropsWithChildren) {
  const config = useConfigContext();
  if (!config?.featureFlag.clientId) {
    return (
      <>
        { children }
      </>
    );
  }

  return (
    <LDProvider
      clientSideID={config.featureFlag.clientId}
      reactOptions={({
        useCamelCaseFlagKeys: false
      })}
    >
      { children }
    </LDProvider>
  )
}

export function useFeatureFlag<T>(key: string, defaultValue: T): T {
  let flags = useFlags();
  const config = useConfigContext();

  flags = {
    ...flags,
    ...config.featureFlag.overrides
  }

  if (flags[key] !== undefined) {
    return flags[key];
  }

  return defaultValue;
}
